header {
  display: block;
  margin: auto;
  height: 70px;
  background-color: black;
  font-family: "Inconsolata", monospace;
  padding-bottom: 22px;
  border-bottom: 1px solid grey;
}

.header__logo {
  height: 20px;
  margin-top: 22px;
}

.header__nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
}

.header__nav li {
  display: inline-block;
  margin-top: 24px;
  margin-left: 25px;
}

.header__nav-link {
  text-decoration: none;
  color: #fff;
  border: 1px solid transparent;
}

.header__nav-link:visited {
  color: #fff;
}

.header__nav-link:hover {
  border: 1px solid #fff;
  border-radius: 5px;
}
