.projects-bg::before {
  content: "";
  background-image: url(/public/stack-bg.webp);
  opacity: 0.5;
  background-size: cover;
  width: 1024px;
  height: 700px;
  position: absolute;
}

.projects-bg {
  height: 704px;
}

.projects {
  position: relative;
  top: 30px;
  left: 10%;
  background-color: #000;
  width: 80%;
  border: 1px solid gray;
  font-family: "Courier New", Courier, monospace;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.3;
}

.pr-tab {
  margin-left: 5px;
}

.projects__elem:hover {
  cursor: pointer;
  color: #07df00;
}

.active {
  background-color: #fff;
  color: #000;
}

.active:hover {
  background-color: rgb(190, 190, 190);
  color: #000;
}

.projects-head-title {
  font-weight: 600;
  font-size: 20px;
}

.projects-title {
  font-weight: 600;
}

.projects__description {
  padding-top: 10px;
  border-top: 1px solid gray;
}

.projects__description img {
  width: 100%;
}

.projects__description a {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  border: 2px solid #fff;
  padding: 2px 5px;
  float: right;
  margin-right: 5px;
}

.mobile-warning {
  display: none;
}

@media (max-width: 768px) {
  .projects-bg::before {
    width: 100%;
    height: 90%;
  }

  .projects {
    width: 90%;
    left: 5%;
  }
  .projects__description a {
    padding: 5px 35px;
  }
  .projects__description-mobile-tab {
    position: relative;
    margin-top: 50px;
  }
  .mobile-warning {
    display: contents;
    text-align: center;
  }
}
