body {
  color: #fff;
  background-color: #000;
  font-family: sans-serif;
}

.container {
  margin: auto;
  max-width: 1024px;
  height: 100%;
}

.wrapper {
  margin-top: 30px;
  height: 700px;
  margin-bottom: 30px;
}

.yellow {
  color: rgb(235, 235, 43);
}

.blue {
  color: skyblue;
}

.pink {
  color: rgb(231, 90, 231);
}

.green {
  color: green;
}

.darkBlue {
  color: rgb(88, 145, 192);
}

.gray {
  color: gray;
}
